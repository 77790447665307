.services {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}
