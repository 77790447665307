*, *:after, *:before {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

body {
  background-color: black;
}

.bg {
  background-color: darkred;
  -webkit-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);
  clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);
}

@media (max-width: 575px) {
  .bg {
    -webkit-clip-path: polygon(0 0, 100% 3%, 100% 100%, 0 97%);
    clip-path: polygon(0 0, 100% 3%, 100% 100%, 0 97%);
  }
}
