.container {
  max-width: 1200px;
}

.brand {
  transition: color 0.25s ease-in-out;
}

.dk {
  color: red !important;
  font-weight: 900;
}

.dojo {
  color: white;
  font-weight: 100;
}


.toggler {
  border: 1px solid white;
  border-radius: 2px;
}

.navlink {
  color: red;
  font-weight: 300;
  padding-inline: 1rem;
  transition: 0.25s ease-in-out;
}

.navlink:hover {
  color: white;
}

.navlink.active {
  color: white;
}

.contact {
  cursor: pointer;
}

@media (min-width: 900px) {
  .navlinkWrapper {
    gap: 2rem;
  }
}

@media (max-width: 575px) {
  .navlinkWrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
}