.headerWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: -2rem;
}

.instagram {
  top: 100;
  position: absolute;
  right: 30vw;
  padding: 1rem;
  transform: translateY(3rem);
}

@media (max-width: 1200px) {
  .instagram {
    right: 10vw;
  }
}

@media (max-width: 575px) {
  .instagram {
    right: 0;
  }
}

.header {
  background-image: url('../../assets/images/fitness.jpg');
  background-position: center;
  height: 40rem;
  max-width: 1200px;

  align-items: flex-start;
  display: flex;
  align-items: center;
}

.h1 {
  font-size: 4rem;
  margin: 4rem 2rem;
  white-space: nowrap;
}

.dk {
  font-weight: 900;
}

.dojo {
  color: white;
  font-weight: 100;
}

.h4 {
  font-style: italic;
  font-weight: 300;
  color: red;
  margin: 2rem 0 1rem 2rem;
}

.cite {
  font-size: 1rem;
  text-align: right;
  white-space: nowrap;
}

.h6 {
  font-style: italic;
  font-weight: 100;
  color: silver;
  margin: 1rem 0 2rem 2rem;
}

.button {
  border: 1px solid red;
  background-color: black;
  color: red;
  font-size: clamp(0.75rem, 4vw, 1.5rem);
  font-weight: 300;
  letter-spacing: 0.125rem;
  margin: 2rem;
  padding: clamp(0.5rem, 1.5vw, 1rem) clamp(1.25rem, 3vw, 2.25rem);
  transition: all 0.25s ease-in-out;
}

.button:hover {
  border-color: white;
  color: white;
}
