.form {
  display: flex;
  flex-direction: column;
  margin-bottom: 10rem;
  max-width: 600px;
}

.input {
  background-color: transparent;
  color: white;
  border-radius: 0.25rem;
  border: .125rem solid black;
  margin-bottom: 0.5rem;
}

.input::placeholder,
.textarea::placeholder {
  color: gray;
}

.textarea {
  background-color: rgb(50, 50, 50);
  color: transparent;
  text-shadow: 1px 1px black;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  border: .125rem solid black;
  resize: none;
}

.label {
  align-self: center;
  display: flex;
  justify-content: flex-start;
}

.input {
  background-color: rgb(50, 50, 50) !important;
  align-self: center;
  display: flex;
  justify-content: flex-start;
}

.row {
  padding-inline: 0.25rem;
}

.recaptchaWrapper {
  margin-bottom: -1rem;
  margin-left: 1rem;
}

.invalid {
  color: red;
  height: 0;
  position: relative;
  transform: translateY(-2.25rem);
}

.button {
  border: 1px solid white;
  border-radius: 2px;
  background-color: transparent;
  color: white;
  font-weight: 100;
  font-size: 1.25rem;
  font-weight: 100;
  margin: 0 1px;
  padding: 1rem 2rem;
  transition: background 0.25s ease-in-out;
  transform: translateY(2rem);
  width: fit-content;
}

.button:hover {
  background-color: darkred;
  color: white;
}
