a,a:visited {
  color: red;
  text-decoration: none;
}

.textRed {
  color: red;
}

.textSilver {
  color: silver;
}
